@import './common.scss';

.spinner {
  display: inline-block;
  position: relative;
  width: $round_button_size;
  height: $round_button_size;
}

.spinner_arc {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: $round_button_size;
  height: $round_button_size;
  border: 8px solid $prim_color;
  border-radius: 50%;
  animation: spinner 3s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $prim_color transparent transparent transparent;
}

.spinner_arc_1 {
  animation-delay: -0.45s;
}
.spinner_arc_2 {
  animation-delay: -0.3s;
}
.spinner_arc_3 {
  animation-delay: -0.15s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
