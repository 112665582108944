.gallery{
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    margin-top: 1em;
    column-gap: 3%;
    /*padding: 0 12px*/
}

.gallery .pics{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 4%;
    border-radius: 10px;
    /*overflow:hidden;*/
}

/* .gallery .pics:hover{ */
    /* filter: opacity(0.8); */
/* } */

/*@media(max-width: 900px){*/
/*    .gallery{*/
/*        -webkit-column-count: 3;*/
/*        -moz-column-count: 3;*/
/*        column-count: 3;*/
/*    }*/
/*}*/

/*@media(max-width: 800px){*/
/*    .gallery{*/
/*        -webkit-column-count: 2;*/
/*        -moz-column-count: 2;*/
/*        column-count: 2;*/
/*    }*/
/*}*/

/*@media(max-width: 500px){*/
/*    .gallery{*/
/*        -webkit-column-count: 1;*/
/*        -moz-column-count: 1;*/
/*        column-count: 1;*/
/*    }*/
/*}*/

/*@media(max-width: 500px){*/
/*    .gallery{*/
/*        -webkit-column-count: 2;*/
/*        -moz-column-count: 2;*/
/*        column-count: 2;*/
/*    }*/
/*}*/

.picture{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.picture.full_screen{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    z-index: 100000;
}

.picture img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 10px 0 10px;
    margin: 0 auto;
}
