$border-radius: 10;
$secondary-bg: white;
$speed: 100;

.menu-container{
    /*display: flex;*/
    font-size: 18px;
}

.menu-trigger img{
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    background-color: #fff;
}

.dropdown-menu{
    position: absolute;
    background-color: #fff;
    border-radius: $border-radius;
    padding: 10px 20px;
    width: 200px;
    border: 1px solid;
    border-color: #faf7f7;
    z-index: 10000;
}

// small white rectangle
//.dropdown-menu::before{
//    //content: '';
//    //position: absolute;
//    //top: -5px;
//    //left: 20px;
//    //height: 20px;
//    //width: 20px;
//    //background: $secondary-bg;
//    //transform: rotate(45deg);
//}

.dropdown-menu.active{
    //opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: $speed ease;
    box-shadow: 5px 5px 5px lightgrey;
}

.dropdown-menu.inactive{
    //opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: $speed ease;
}

.dropdown-menu ul li{
    padding: 10px  0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menu ul li:hover a{
    color: rgb(212, 33, 9);
    cursor: pointer;
}

.dropdown-menu ul li:hover img{
    //opacity: 1;
    cursor: pointer;
}

.dropdownItem{
    display: flex;
    margin: 10px auto;
    //opacity: 0.7;
}

.dropdownItem img{
    max-width: 24px;
    margin-right: 10px;
    transition: $speed;
}

.dropdownItem a{
    max-width: 100px;
    margin-left: 10px;
    transition: $speed;
}

h3{
    width: 100%;
    text-align: center;
    font-size: 18px;
    padding: 20px 0;
    font-weight: 500;
    //font-size: 18px;
    margin-top: 0;
    padding-top: 0.5em;
    color: var(--primary-text-color);
    line-height: 1.2rem;
}

h3 span{
    font-size: 14px;
    color: var(--secondary-text-color);
    font-weight: 400;
}
