@import './common.scss';

.div_landing_page{
    text-align: left;
    font-size: 20px;
    margin: 2.5em 1em 1em 1em;
    /*margin-left: 1em;*/

    /* Magic to prevent padding from extending the width */
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.label_header {
    color: $prim_color;
    text-align: left;
}

.label_landing_text {
    text-align: center;
    color: $btn_vocab_word_color;
}
