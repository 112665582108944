@import '../common.scss';

$btn_radius: 120;
$btn_center_size: 54px; //center button size
$btn_size: 48px; //child button size
$circle_size: ($btn_radius * 1px + $btn_size + 5px) * 2;
$radial_button_z_index: 2000;

// Colors
// $radial_btn_main_font_color: $btn_vocab_word_color;
// $radial_btn_main_bg_color: #f6f5f3;
$radial_btn_main_bg_color: white;
$radial_btn_left_bg: #46ffb7;
$radial_btn_center_bg: #ffdf2e;
$radial_btn_right_bg: #53c7ff;

$open_time: 0.2s;
$center_coord:  100px;
$y_correction: 25;

.radial.open .fa {
  opacity: 100%; // child button's opacity
  pointer-events: auto; // allow click events
}

.radial {
  opacity: 95%; // circle's opacity
}

:export {
  radial_button_z_index: $radial_button_z_index;
}
