@import 'src/scss/radial_buttons/trigonometry';
@import 'src/scss/radial_buttons/radial_btn_left';
@import 'src/scss/radial_buttons/radial_btn_right';
@import 'src/scss/radial_buttons/radial_btn_center';
@import 'src/scss/radial_buttons/radial_btn_common';
@import "src/scss/common";
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);

//$primary_color: #28C38A;
//$primary_light: #60fcc3;
//$open_time: 0.2s;
//$center_coord:  100px;


.img_radial{
  width: $btn_size;
  height: $btn_size;
}

body {
  font-family: "Lato", sans-serif;
  background: #efefef;
}

button:focus {
  outline: 0;
}


@keyframes glowing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    // opacity: 100;
    opacity: 0; // make the button invisible when open
  }
}

// central button
.fab {
  border: none;
  // color: white;
  color: $btn_vocab_word_color;
  // background-color: $prim_color;
  background-color: $radial_btn_main_bg_color;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.2);
  
  border-radius: 50%;
  width: $btn_center_size;
  height: $btn_center_size;
  font-size: $font_size_default;

  padding: 0; //needed for Safari
  // text-align: center;
  // justify-content: center; 
  // align-items: center;

  // font-weight: bold;
  //transition: all 0.5s;
  // animation: glowing 200ms;
}
.fab:hover {
  //background-color: $primary_light;
}

// Child buttons
// Initial state
.fa {
  opacity: 0;
  pointer-events: none; // block click events
  position: absolute;
  background: transparent;
  border: none;
  transition: all $open_time;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fa:hover {
  transition-delay: $open_time;
  opacity: 0; // make the button invisible when open
  // filter: opacity(0.8);
}


.radial.open .fab {
  // background-color: $prim_color;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.2);
  // animation: glowing 500ms;
  // transform: translate(
  //                 0,
  //                 $circle_size/2 - $btn_center_size/2);
  opacity: 0; // make the button invisible when open
  // transition-duration: 1ms;
}


.plus {
  //transition: all 0.5s;
  // text-align: center;
  // justify-content: center; 
  // align-items: center;
}

.radial.open .plus {
  //transform: rotateZ(30deg);
}



