$red: #E91E63;
$blue: #3F51B5;
$grey: #EAE8E9;
$grey2: #f3f3f3;
$white: #FFF;

.card_form{
  margin: 0;
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

.label_name{
  display: inline-block;
  margin-bottom: 9px;
  color: #313131;
  font-size: 14px;  /* Other 2Checkout form elements also have 14px size, not clear how to change it */
  font-weight: 300;
  line-height: 17px;
}

.field_name{
  overflow: visible;
  /*margin: 0px 100px;*/
  font-family: inherit;
  display: block;
  width: 100%;
  height: 42px;
  padding: 0px 12px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #313131;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #CBCBCB;
  border-radius: 3px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  outline: 0;

  /* Magic to prevent padding from extending the width */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.additional_info{
  text-align: justify;
  color: gray;
  fontWeight: normal;
}

#cardForm {
  max-width: 50.75em;
  margin: 0 auto;
  /*padding: 1.875em;*/
}

.hosted-field {
  height: 32px;
  margin-bottom: 1em;
  display: block;
  background-color: transparent;
  color: rgba(0, 0, 0, .87);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .26);
  outline: 0;
  width: 100%;
  font-size: 16px;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  position: relative;
}

.hosted-fields--label {
  line-height: 32px;
  margin-bottom: 0.5em;
}

.icon{
  margin-right: 0.5em
}

.pay-button {
  background: #E91E63;
  color: #fff;
  margin: 0 auto;
  border: 0;
  border-radius: 3px;
  padding: 1em 3em;
  font-size: 1em;
  text-transform: uppercase;
  box-shadow: 0 0 2px rgba(0, 0, 0, .12), 0 2px 2px rgba(0, 0, 0, .2);
}

.button-container {
  display: block;
  text-align: center;
}


