$font-size: 16px;
$round_button_size: 48px;
$prim_color: #28c388;
$prim_color_text: #1a865d;
// $prim_color: #1dab61;
$color_gray: #37383C;
$color_light_gray: gray;
$color_bg: white;
$color_very_light_gray: #c7c7c7;

// Key action button
$btn_primary_color: white;
$btn_primary_bg: $prim_color;
$btn_primary_shadow: rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px;

// Button regular
$btn_secondary_color: #4a4a49;
$btn_secondary_bg: #ffffff;

$btn_secondary_radius: 20px;
$btn_secondary_shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.2);

$btn_vocab_word_color: $btn_secondary_color;
$btn_padding: 0.7em 2em 0.7em 2em;
$font_size_default: 16px;
$font_size_vocabulary: $font_size_default;

$mobile_view_max_size: 1280px;

$central_column_padding: 1em;
$central_column_max_width: 900px;

// Export variables to use in JS code
:export {
    prim_color: $prim_color;
    prim_color_text: $prim_color_text;
}

// Button gray - #9a9996

// #WA style
// Background (white) - #ffffff
// Primary buttons
// Icon (green) - #1dab61

// Sec buttons
// Icon (black) - #121b22
// Background (light green) - #d8fdd2
// Dark green (selected icon) - #15603f

// Third buttons
// Icon (dark grey) - #6d7578
// Background (light grey) - #f6f5f3