@import './common.scss';

.pulse_container {
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

// Pulse button styles

// initial size - small
.pulse-small {
  padding: 0.4rem !important;
  position: absolute !important;
  border-radius: 50% !important;
}

// initial size - big
.pulse-big {
  padding: 1.5rem !important;
  position: absolute !important;
  border-radius: 50% !important;
}

// inner circle
.pulse-bg {
  background-color: $prim_color;
  //z-index: -1;
  opacity: 1;
  animation: pulse 1s ease-in infinite;
}

// outer circle - slightly lighter
.pulse-bg-2 {
  background-color: lighten($prim_color, 10%);
  //z-index: -1;
  opacity: 1;
  animation: pulse 1s ease-out infinite;
}

// inner circle
.pulse-bg-3 {
  background-color: $prim_color;
  //z-index: -1;
  opacity: 1;
  animation: pulse-2 1.5s ease-in infinite;
}

// outer circle - slightly lighter
.pulse-bg-4 {
  background-color: lighten($prim_color, 10%);
  //z-index: -1;
  opacity: 1;
  animation: pulse-2 1.5s ease-out infinite;
}

// Animation of the pulse elements

@keyframes pulse {
  0% {
    transform: scale(1.5);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    transform: scale(7);
    opacity: 0;
  }
}

@keyframes pulse-2 {
  0% {
    transform: scale(1.5);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    transform: scale(5);
    opacity: 0;
  }
}