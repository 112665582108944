@import './common.scss';

/* The toggle */
.toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .toggle {
  background-color: $prim_color;
}

input:focus + .toggle {
  box-shadow: 0 0 1px $prim_color;
}

input:checked + .toggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* The switch - the box around the toggle */
.switch {
  /*display: block;*/
  position: relative;
  display: inline-block;
  min-width: 60px;
  height: 34px;
  margin-left: 2em;
}
