@import 'src/scss/radial_buttons/trigonometry';
@import 'src/scss/radial_buttons/radial_btn_common';

$clockwise: -1; // +1 = clockwise, -1 = counter clockwise
$angle_initial: -3deg * $clockwise;
$angle_step: 45deg * $clockwise;
$x_correction: 25;

@function get_x($cur_angle) {
  $x: calc( sin($angle_initial + $cur_angle) * $btn_radius) + $btn_radius + $x_correction + px;
  @return $x;
}

@function get_y($cur_angle) {
  $y: calc(-1 * cos($angle_initial + $cur_angle) * $btn_radius) + $btn_radius/2 + $y_correction * 1px;
  @return $y;
}

// background circle with all buttons inside
#radial_right.radial {
  width: $btn_center_size;
  height: $btn_center_size;
  
  background: transparent;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.2);

  border-radius: 50%;
  transition: all $open_time;
  position: absolute;
  top: 30px;
  //right: 30px;
  left: calc(100% - $btn_center_size - 30px); // 30px from right border to prevent drawing bugs
  z-index: $radial_button_z_index;
}

#radial_right.radial.open {
  // open size
  height: $circle_size;
  width: $circle_size;

  background: $radial_btn_right_bg;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.2);

  transform: translate(
                  -($circle_size/2 - $btn_center_size/2),
                  -($circle_size/2 - $btn_center_size/2)
  );
  //position: fixed;
  //bottom: 310px;
  //z-index: 1;
}

.radial.open .fa_right-1 {
  $button_number: 1;
  $cur_angle: $angle_step * ($button_number - 1);
  transform: translate(get_x($cur_angle),get_y($cur_angle),);
}
.radial.open .fa_right-2 {
  $button_number: 2;
  $cur_angle: $angle_step * ($button_number - 1);
  transform: translate(get_x($cur_angle),get_y($cur_angle),);
}
.radial.open .fa_right-3 {
  $button_number: 3;
  $cur_angle: $angle_step* ($button_number - 1);
  transform: translate(get_x($cur_angle),get_y($cur_angle),);
}

.radial.open .fa_right-4 {
  $button_number: 4;
  $cur_angle: $angle_step* ($button_number - 1);
  transform: translate(get_x($cur_angle),get_y($cur_angle),);
}

.radial.open .fa_right-5 {
  $button_number: 5;
  $cur_angle: $angle_step* ($button_number - 1);
  transform: translate(get_x($cur_angle),get_y($cur_angle),);
}

.radial.open .fa_right-6 {
  $button_number: 6;
  $cur_angle: $angle_step* ($button_number - 1);
  transform: translate(get_x($cur_angle),get_y($cur_angle),);
}
