@import './common.scss';
@import './spinner.scss';
@import "./toggle_button";

body {
  background-color: white;
}

//laptop screen
@media (min-width: $mobile_view_max_size) {
  body {
    // background-color: #A0C1B5;
    background-color: #f4f4f4;
  }
}

.App {
  display: block;
  text-align: center;
}

.center_column {
  display: block;
  width: 100%;
  min-height: 100vh;
  max-width: $central_column_max_width;
  margin: 0px auto;
  background-color: white;
  box-shadow: 6px 2px 30px 0px rgba(0, 0, 0, 0.75);
  padding: $central_column_padding;
  font-size: $font_size_vocabulary;

  /* Prevents child elements from getting out of the column */
  overflow-x: hidden;
  overflow-y: visible;

  /* Magic to prevent padding from extending the width */
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.div_top_menu{
  position: fixed;
  background-color: $color_bg;
  width: calc(100% - $central_column_padding*2);
  padding: $central_column_padding;
  z-index: 2000;
  // transition: transform 0.1s linear;
}

//laptop screen view
@media (min-width: $central_column_max_width) {
  .div_top_menu{
    position: fixed;
    background-color: $color_bg;
    width: calc(100% - $central_column_padding*2);
    padding: $central_column_padding;
    z-index: 2000;

    max-width: calc($central_column_max_width - $central_column_padding*2);
    left: calc(50% - $central_column_max_width/2);
  }
}

.div_bottom_menu{
  position: fixed;
  background-color: $color_bg;
  width: calc(100% - $central_column_padding*2);
  padding: $central_column_padding;
  z-index: 2000;
  bottom: 0;
  // transition: transform 0.1s linear;
}

//laptop screen view
@media (min-width: $central_column_max_width) {
  .div_bottom_menu{
    position: fixed;
    background-color: $color_bg;
    width: calc(100% - $central_column_padding*2);
    padding: $central_column_padding;
    z-index: 2000;
    bottom: 0;

    max-width: calc($central_column_max_width - $central_column_padding*2);
    left: calc(50% - $central_column_max_width/2);
  }
}

.div_menu {
  display: flex;
  margin-left: $central_column_padding;
  /*text-align: right;*/
}

.icon_menu {
  /*display: flex;*/
  width: 24px;
  height: 24px;
  /*margin: 1em 1em 0 0;*/
}

.icon_menu_small {
  width: 0.8em;
  height: 0.8em;
  // margin: auto;
  /*margin: 1em 1em 0 0;*/
}

.icon_mode {
  display: inline;
  width: 32px;
  height: 32px;
  /*margin: 1em 0 0 0;*/
}

.icon_button {
  display: inline;
  width: 48px;
  height: 48px;
  /*margin: 1em 0 0 0;*/
}

.image_header {
  margin-top: 1em;
}

.input {
  display: inline;
  // margin: 0 1em 0 1em;

  resize: none;
  min-height: 1em;
  max-height: 10em;

  text-align: center;
  //font-family: inherit;
  //width: 60%;
  border: 0;
  border-bottom: 2px solid $prim_color;
  outline: 0;
  color: $color_gray;
  /*padding: 7px 0;*/
  background: transparent;
  transition: border-color 0.2s;
}

.input_word {
  // width: 100%;
  font-size: 20px;
}

.input_page_number {
  width: 40%;
  font-size: $font-size;
}

.big_input {
  border: 1px solid $btn_secondary_color;
  font-size: $font-size;
}

.input_settings {
  font-size: 18px;
  width: 90%;
  margin: 0.5em 0 0 0;
}

.select {
  display: inline;
  text-align: center;
  border: 0;
  border-bottom: 2px solid $prim_color;
  outline: 0;
  color: $color_gray;
  padding: 7px 0;
  background: transparent;
  width: auto;
  font-size: 18px;
}

.select_word {
  display: none;
  font-size: 20px;
}

.button {
  font-size: 20px;

  background-color: $prim_color;
  border-radius: 0px;
  color: $color_bg;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  display: inline-block;
  text-align: center;
  touch-action: manipulation;
}

.button:disabled {
  font-size: 20px;
  /*padding-bottom: 10px;*/

  background-color: $color_very_light_gray;
  border-radius: 0px;
  box-shadow: $color_very_light_gray 0 1px 2px, $color_very_light_gray 0 2px 4px, $color_very_light_gray 0 4px 8px, $color_very_light_gray 0 8px 16px;
  color: $color_bg;
  //font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  display: inline-block;
  text-align: center;
  touch-action: manipulation;
}

.button_read_text {
  height: 2.4em;
  margin-left: 0.5em;
  padding: 0.5em 0 0.5em 0; //make the button space a bit bigger for user's convenience
}

.button_read_text_hidden{
  height: 2.4em;
  margin-left: 0.5em;
  padding: 0.5em 0 0.5em 0; //make the button space a bit bigger for user's convenience

  opacity: 0;
  margin-bottom: -2.4em;
}

.button_vocabulary {
  color: $btn_vocab_word_color;
  background-color: $btn_secondary_bg;
  font-size: $font_size_vocabulary;
  display: block;
  width: 40%;
  margin: 0.5em;
  padding: 0.3em;
  // box-shadow: $btn_secondary_shadow;
}

.button_word {
  color: $btn_vocab_word_color;
  border-radius: $btn_secondary_radius;
  box-shadow: $btn_secondary_shadow;
}

.button_put_off {
  width: 4em;
  padding: 0.3em;
  display: inline-block;
  border-radius: $btn_secondary_radius;
  box-shadow: $btn_secondary_shadow;
}


.button_primary {
  font-size: $font_size_vocabulary;
  padding: $btn_padding;
  border-radius: $btn_secondary_radius;
  color: $btn_primary_color;
  background-color: $btn_primary_bg;
  box-shadow: $btn_primary_shadow;
}

.button_secondary {
  color: $btn_secondary_color;
  font-size: $font_size_vocabulary;
  padding: $btn_padding;
  background-color: $btn_secondary_bg;
  border-radius: $btn_secondary_radius;
  box-shadow: $btn_secondary_shadow;
}

.button_action_vocab_page {
  width: 70%;
  max-width: 15em;
}

.button_round {
  border-radius: 50%;
  height: 48px;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.button_secondary_shadow {
  box-shadow: $btn_secondary_shadow;
}

.button_primary_shadow {
  box-shadow: $btn_primary_shadow;
}

.button_action {
  width: auto;
  margin: 1em 0 0 0;
}

.button_secondary {
  background-color: $color_bg;
  color: $btn_secondary_color;
  box-shadow: $btn_secondary_shadow;
  // border: 1px solid $prim_color;
}

.button_small {
  padding: 20px;
  margin: 0px;
  font-size: 20px;
}

.button_menu {
  color: $btn_secondary_color;
  background-color: transparent;
  border-width: 0px;
  // font-weight: bold;
  margin-top: 2em;
  font-size: 20px;
}

.button_page {
  width: 2em;
  height: 2em;
  padding: 0px;
  font-size: $font_size_vocabulary;
  border-radius: $btn_secondary_radius;
  // box-shadow: $btn_secondary_shadow;
}

.button_remove {
  font-size: $font_size_vocabulary;
  display: flex;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background-color: $color_bg;
  vertical-align: center;
  // color: white;
  border: 0;
  cursor: pointer;
  margin: 0.5em;
}

.button_back {
  font-size: $font_size_vocabulary;
  width: 16px;
  padding: 0.7em;
  vertical-align: middle;
  box-shadow: $btn_primary_shadow;
}

.button_copy {
  max-height: 38px;
  max-width: 38px;
}

.button_google_sso {
  width: 100px;
}

.button_icon {
  background: none;
  border: 0;
  padding: 0;
}

.button_floating {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2.5em;
  font-size: 20px;
  color: white;
  background-color: $prim_color;
  border: 0;
}

.div_feedback_content {
  text-align: center;
}

.textarea {
  // width: 100%;
  min-height: 3em;
  // margin-right: 3em;
  /*height: 60%;*/
  font-size: 18px;
  text-align: left;
  resize: none;
  rows: 10;
  padding: 10px;
  // border-width: 10px;
  border-radius: 10px;
  //font-family: Roboto, sans-serif;
  // box-shadow: 0px -1px 10px -4px rgba(0,0,0,0.75);
  box-shadow: $btn_secondary_shadow;

  /* Magic to prevent padding from extending the width */
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.textarea_feedback {
  color: $color_gray;
  background-color: #fff;
  margin: 0.5em 0 0 0;
  width: 100%;
}

.textarea_text {
  color: $color_gray;
}

.textarea_text_request {
  // color: white;
  // background-color: $prim_color;
  margin: 1em 0em 0.5em 5em;
  width: auto;
}

.textarea_translation {
  color: white;
  background-color: $prim_color;
  // readOnly: true;
  // rows: "10";
  // margin-top: 0.5em;
}

.settings_header {
  display: inline;
  width: auto;
  margin-left: 20px;
  font-size: 20px;
  font-weight: bold;
  vertical-align: middle;
}

.label {
  display: block;
  width: auto;
  border: 0px;
  text-align: left;
  font-size: 18px;
  font-weight: bold;
}

.label_description {
  color: gray;
  font-weight: normal;
}

.label_button_title {
  margin-top: 0.5em;
  color: $btn_secondary_color;
}

//laptop screen view
// @media (min-width: $mobile_view_max_size) {
//   .label_button_title {
//     margin-left: 0.5em;
//       margin-top: 0;
//     color: $btn_secondary_color;
//   }
// }


.label_button {
  width: auto;
  display: inline;
  vertical-align: middle;
  font-size: 14px;
}

.label_warning {
  font-size: 18px
}

.label_text {
  // width: 100%;
  color: $btn_vocab_word_color;
  font-weight: normal;
}

// .label_translation {
//   width: 100%;
//   color: $prim_color;
//   font-weight: normal;
// }

.label_notification {
  display: none;
  width: auto;
  margin-top: 20px;
  //text-align: left;
  font-size: 18px;
  text-align: center;
}

.div_vertical {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.div_vertical_left {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.div_horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.div_horizontal_toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.div_play_controls {
  width: 100%;

  justify-content: space-around;
  margin-top: 1em;
}

//laptop screen view
@media (min-width: $mobile_view_max_size) {
  .div_play_controls {
    width: 60%;

    justify-content: space-around;
    margin-top: 1em;
  }
}

.div_key_button {
  width: 4em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

//laptop screen view
// @media (min-width: $mobile_view_max_size) {
//   .div_key_button {
//     width: 8em;

//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//   }
// }

.div_radial_buttons {
  width: 80%;

  margin: 0px;
  position: absolute;
  // height: 100px;
}

//laptop screen view
@media (min-width: $mobile_view_max_size) {
  .div_radial_buttons {
    width: 60%;

    margin: 0px;
    position: absolute;
    // height: 100px;
  }
}

.div_multiple_words {
  display: none;
}

.div_text_and_translation {
  display: none;
  padding: 30px 0 0 0;
}

.div_podcast_controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2em 0 0 0;

  /* Magic to prevent padding from extending the width */
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.div_settings_header {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
}


.background_modal {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0px auto;
  position: absolute;
  top: 0;
}

.div_sub_page {
  /*position: absolute;*/
  color: $btn_secondary_color;
  display: none;
  width: 100%;
  // min-height: 100vh;
  text-align: left;
  flex-direction: column;
  background: $color_bg;
  opacity: 1;
  border-radius: 4px;
  padding: 2%;

  /* Magic to prevent padding from extending the width */
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.div_link_container {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  row-gap: 0.5em;
  column-gap: 1.5em;

  /* Magic to prevent padding from extending the width */
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.div_link {
  width: auto;
  height: auto;
  max-width: 100px;
  color: $color_bg;
  text-align: center;

  /* Magic to prevent padding from extending the width */
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.div_words {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  margin-top: 1em;
}

.vocabulary_word {
  font-size: $font_size_vocabulary;
  border-radius: $btn_secondary_radius;
  display: block;
  width: 60%;
  margin: 0.5em;
  padding: 0.3em;
  max-width: 20em;
}

.vocabulary_word_not_selected {
  background-color: $color_bg;
  color: $btn_vocab_word_color;
  box-shadow: $btn_secondary_shadow;
}

.vocabulary_word_selected {
  background-color: $prim_color;
  box-shadow: $btn_primary_shadow;
}


.image {
  max-width: 48px;
  max-height: 48px;
  /*height: auto;*/
  margin: auto;
  padding: 0.5em;
  display: inline;
  vertical-align: middle;
}

.slider-wrapper {
  display: inline-block;
  height: 17px;
  width: 90%;
  padding: 0;
  margin-bottom: 1em;
  z-index: 100000;
}

.slider-wrapper input {
  /*https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/range*/
  accent-color: #1d9669;
  background: $prim_color;
  border: solid 1px #1d9669;
  border-radius: 8px;
  height: 0.6em;
  width: 100%;
  outline: none;
  -webkit-appearance: none;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
}

.warning {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff4545;
  margin-bottom: 1em;
  height: 3em;
  //textAlign: center;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}